
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import NavBarComponent from './Components/NavBarComponent';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Homepage from './Pages/Homepage';
import AccommodationPage from './Pages/AccommodationPage';
import EventPage from './Pages/EventPage';
import Imagegallery from './Pages/Imagegallery';
import AboutUsPage from './Pages/AboutUsPage';
import ContactUs from './Pages/ContactUs';
import Restaurants from './Pages/Restaurants';
import ErrorPage from './Pages/ErrorPage';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <NavBarComponent /> */}
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/4bh-private-villas-kalyan' element={<AccommodationPage />} />
          <Route path='/villas-for-family-kalyan' element={<EventPage />} />
          <Route path='/private-villas-kalyan' element={<Imagegallery/>}/>
          <Route path='/luxury-villas-kalyan-pool' element={<AboutUsPage/>}/>
          <Route path='/villas-near-kalyan' element={<ContactUs/>}/>
          <Route path='/villas-dining-services-kalyan' element={<Restaurants/>}/>
          <Route path='/*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
