import React from 'react';
import MetaTags from 'react-meta-tags';

import "../CSS/Events.css";
import NavBarComponent from '../Components/NavBarComponent';
import BachelorParty from "../Images/Bachelor Party.jpg";
import OfficeParty from "../Images/Company-party.jpg";
import Birthdayparty from "../Images/Birthday-Party.jpg";
import wedding from "../Images/weeding.webp";
import Show from "../Images/section-separator-bottom.png";

import Footer from "../Components/Footer";
import ShowImage01 from "../Images/divider.png"

import Slide from 'react-reveal/Slide';
import LightSpeed from 'react-reveal/LightSpeed';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

// import { MetaTags } from 'react-meta-tags';



const EventPage = () => {
    return (
        <>

<MetaTags>
      <title>Villas for Family in Kalyan | Green Hill Villas</title>
        <meta title="Villas for Family in Kalyan | Green Hill Villas" />
        <meta
          name="description"
          content="Discover the perfect Villas for Family vacations in Kalyan. Find spacious and comfortable accommodations tailored for families."
        />
        <meta
          name="keywords"
          content="Best Villas in Kalyan ,Best Luxury Villas in Kalyan with Pool,Villas for Family in Kalyan,4bh Private Villas in Kalyan,Villas with Dining Services in Kalyan,Private Villas in Kalyan,Villas near Kalyan"
        />
        <link rel="canonical" href="https://greenhillvilla.in/villas-for-family-kalyan" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Villas for Family in Kalyan | Green Hill Villas" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://greenhillvilla.in/villas-for-family-kalyan"/>
        <meta property="og:description" content="Discover the perfect Villas for Family vacations in Kalyan. Find spacious and comfortable accommodations tailored for families." />
        <meta property="og:image" content="https://greenhillvilla.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
            {/* <MetaTags>
                <title>Green Hill Villa's</title>
                <meta name="google-site-verification" content="_lW9f6ufbAfakIEP_HSiTH8KC99LiXGaDnRaIKxvjxU" />
                <meta name="description" content="Best Beach Resort With the best nearby destination. You’ll never run out of things to do when you stay at Sea Sight Resort. Call us now for bookings." />
                <meta name="keywords" content="sea sight resort,resorts in alibaug,resorts in alibaug near beach,alibaug beach resort,resorts in alibaug for family near beach,resorts in alibaug near nagaon beach,best resort in nagaon alibaug,resorts in alibaug for couples,best resorts in alibaug for couples,resorts near alibaug for couples" />
                <meta property="og:title" content=" Green Hill Villa's" />
                <meta property="og:description" content="Best Beach Resort With the best nearby destination. You’ll never run out of things to do when you stay at Sea Sight Resort. Call us now for bookings." />
                <link rel="canonical" href="http://seasightresort.com/best-beach-resort" />
            </MetaTags> */}

            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='EventSection1'>
                <div className='Event-banner'>
                    <NavBarComponent />
                    <div className='Event-banner-text'>
                        {/* <Slide left>
                        <div className='Text'>
                            <h4>An actor has to burn inside with an outer ease.</h4>
                            </div>
                        </Slide> */}
                        <div className='ES1-Text'>
                            <Zoom top>
                                <h2>Villas for Family in Kalyan</h2>
                            </Zoom>
                            <Fade bottom>
                                <p>We make planning easy, so you can focus on celebrating!</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////////// section 2 /////////////////////////////////// */}


            <div className='EventPage-title'>
                <h1>
                    <LightSpeed left cascade>
                        MAKE YOUR SPECIAL DAY
                        MEMORABEL
                    </LightSpeed>
                </h1>
                <p className='below-banner-title'> Let us make your special day unforgettable with our exceptional service and attention to detail at Green Hill Villas for family in Kalyan</p>
                <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
            </div>

            <div className='EventShowCon'>
                <img src={Show} alt="Show Image " />

            </div>
            <section className='EventSection2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Slide left>
                                <div className='EventImageCon'>
                                    <div className='EventImages'>
                                        <img src={wedding} alt="Birthday Party" />
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-6'>
                            <Slide right>
                                <div className='Events_details-Con'>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>LOVE, LAUGHTER & HAPPILY EVER AFTER!</h3>
                                            <h2>Wedding</h2>
                                            <p>
                                            At Green Hill Villas for family in Kalyan, we specialize in creating unforgettable experiences for weddings. Our team works tirelessly to ensure every detail is perfect, from the stunning venue to the exquisite decor, making your special day truly magical.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </section>
    
            {/* /////////////////////////////// section 3 /////////////////////////////////// */}

            <section className='EventSection3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Slide left>
                                <div className='Events_details-Con'>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>LOVE, LAUGHTER & HAPPILY EVER AFTER!</h3>
                                            <h2>CORPORATE Party</h2>
                                            <p>
                                            For corporate events, Green Hill Villas for family in Kalyan, offers a professional yet welcoming atmosphere, ideal for meetings, conferences, and team-building activities. Our dedicated staff will assist you in coordinating every aspect, ensuring your event is both productive and memorable.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>

                        <div className='col-md-6'>
                            <Slide right>
                                <div className='EventImageCon'>
                                    <div className='EventImages'>
                                        <img src={OfficeParty} alt="Office Party" />
                                    </div>
                                </div>
                            </Slide>
                        </div>

                    </div>
                </div>
                <hr></hr>
            </section>



            {/* /////////////////////////////// section 4 /////////////////////////////////// */}

            <section className='EventSection4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Slide left>
                                <div className='EventImageCon'>
                                    <div className='EventImages'>
                                        <img src={BachelorParty} alt="Family Party<" />
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-6'>
                            <Slide right>
                                <div className='Events_details-Con'>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>LOVE, LAUGHTER & HAPPILY EVER AFTER!</h3>
                                            <h2>Family Party</h2>
                                            <p>
                                            Host your family party in style at Green Hill Villas in Kalyan! Whether you're organizing a cozy get-together or a grand celebration, our venue offers an ideal setting for a joyful and memorable event. Allow us to assist you in crafting enduring memories for your special occasion.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </section>
            <section className='EventSection5'>
                <div className='container'>
                    <div className='row'>
                       
                        <div className='col-md-6'>
                            <Slide right>
                                <div className='Events_details-Con'>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>LOVE, LAUGHTER & HAPPILY EVER AFTER!</h3>
                                            <h2>Birthday Party</h2>
                                            <p>
                                            Celebrate your birthday in style at Green Hill Villas for family in Kalyan! Whether you're planning an intimate gathering or a lavish bash, our venue provides the perfect backdrop for a fun and festive celebration. Let us help you create lasting memories on your special day.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-6'>
                            <Slide left>
                                <div className='EventImageCon'>
                                    <div className='EventImages'>
                                        <img src={Birthdayparty} alt="Birthday Party" />
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </section>
            <Footer />

        </>
    )
}

export default EventPage
