

const ReviewsComponent = [
    {
        id: 1,
        Name: "Manasi Shinde",
        Description:"Absolutely stunning villa! The 3-bedroom layout was spacious and beautifully designed, with modern amenities and breathtaking views. The highlight was definitely the private swimming pool, perfect for relaxation and enjoying the sunny days. Highly recommend for a luxurious getaway!",
        // Description: "The stay was pleasant and the food served was excellent.. we celebrated my parents anniversary here and they made it special.. The staff is very helpful and polite. They made sure the food was served hot. Delicious malvani style cooking …",
    },
    {
        id: 2,
        Name: "pooja bapat",
                Description:"Our stay at the 4-bedroom villa was nothing short of exceptional. The villa exuded elegance and comfort, with ample space for our family to unwind. The swimming pool was a hit with everyone, providing endless hours of fun and relaxation. A perfect retreat for a memorable vacation!",

        // Description: "I stayed with my family for 2 days, staff and the owner is very polite very good service ambience has peaceful vibes rooms a very neat and clean non veg food was very tasty with lot of variety with home touch breakfast complementary had lot …",
    },
    {
        id: 3,
        Name: "Sanjeev",
        Description:"A paradise found! The 3-bedroom villa exceeded our expectations in every way. From the stylish interiors to the serene outdoor area with a sparkling swimming pool, every detail was thoughtfully curated. Our stay was simply magical, and we can't wait to return!",
        // Description: "good place to stay with family, cooperative staff , food quality is very good, cleanliness is maintained. I recommend this",
    },
    {
        id: 4,
        Name: "PRAVIN MAHESHKAR",
        Description:"We had the most amazing time at the 4-bedroom villa! The villa was not only spacious and well-appointed but also offered a sense of tranquility that is hard to find elsewhere. The swimming pool was the icing on the cake, offering a refreshing escape from the tropical heat. Truly a gem among villas!",
        // Description: "Excellent resort,nice ambience,very close to beach,hygeine very well maintained,staff was very gentle and courteous Food quality was nice",
    },
    {
        id: 5,
        Name: "Rajesh Chitnis",
        Description:"Our stay at the 3-bedroom villa was nothing short of perfection. The villa was tastefully decorated and equipped with everything we needed for a comfortable stay. The highlight was definitely the private swimming pool, where we spent countless hours soaking up the sun and enjoying the peaceful surroundings. Highly recommend for a relaxing retreat!",

        // Description: "The ambiance was excellent. Staff is also very helpful and polite. Well maintained property. Food quality is also excellent. It was a pleasant experience for us. We will surely visit again.",
    },
]


export default ReviewsComponent;