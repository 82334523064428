import React from 'react';
import MetaTags from 'react-meta-tags';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import NavBarComponent from '../Components/NavBarComponent';
import Footer from '../Components/Footer';
import LightSpeed from 'react-reveal/LightSpeed';

import "../CSS/HomePage.css";
import Tree from "../Images/icon_tree.png";
import Show1 from "../Images/section-separator-bottom.png";
import divider from "../Images/divider.png";
import { ImSpoonKnife } from 'react-icons/im';
import { FaWifi } from 'react-icons/fa';
import { BsDisplay } from 'react-icons/bs';
import { FaShower } from 'react-icons/fa';
import { GiSpotedFlower } from 'react-icons/gi';
import { FaLuggageCart } from 'react-icons/fa';
import { IoBed } from 'react-icons/io5';
import { MdPool } from 'react-icons/md';
import { AiFillCar } from 'react-icons/ai';
import { FaDharmachakra } from 'react-icons/fa';
import { GiRotaryPhone } from 'react-icons/gi';
import { MdFoodBank } from 'react-icons/md';
import ReviewsSwaiper from "../Pages/ReviewsSwaiper";
import ShowImage01 from "../Images/divider.png"

// section 4 Images 
import leftOverlay from "../greenHillVillas-webp/greenHillVilla(28).webp";
import bedImage from "../greenHillVillas-webp/greenHillVilla(39).webp";

// Section 5 Images 
import EnjoyFriend1 from "../greenHillVillas-webp/greenHillVilla(56).webp";
import EnjoyFriend2 from "../greenHillVillas-webp/greenHillVilla(51).webp";
import Enjoypartner2 from "../greenHillVillas-webp/greenHillVilla(52).webp";
import Enjoypartner3 from "../greenHillVillas-webp/greenHillVilla(12).webp";

// Section 6 

import Couplebed from "../Images/Couple Room.jpg";
import Familybed from "../greenHillVillas-webp/greenHillVilla(22).webp";
import ThreeBh from "../greenHillVillas-webp/greenHillVilla(34).webp";

// Section 7 

import JetSkiing from "../Images/SortImages/Untitled-18.jpg";
import Parasailing from "../greenHillVillas-webp/greenHillVilla(55).webp";
import FlyboardFlying from "../Images/SortImages/Untitled-15.jpg";
import bananaride from "../Images/SortImages/Untitled-13.jpg";

// Homepage Resturant 

import HomeResturant from "../greenHillVillas-webp/greenHillVilla(26).webp";



// swiper 



import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import fourBh from "../4BHvilla-webp/4bh(1).webp";
// Video 

import Video from "../Video/sea sight video Two.mp4";
// import { MetaTags } from 'react-meta-tags';



const Homepage = () => {
    return (
        <>

<MetaTags>
      <title>Best Villas in Kalyan | Green Hill Villas</title>
        <meta title="Best Villas in Kalyan | Green Hill Villas" />
        <meta
          name="description"
          content="Discover the epitome of luxury living with our selection of the Best Villas in Kalyan. Immerse yourself in unparalleled comfort ."
        />
        <meta
          name="keywords"
          content="Best Villas in Kalyan ,Best Luxury Villas in Kalyan with Pool,Villas for Family in Kalyan,4bh Private Villas in Kalyan,Villas with Dining Services in Kalyan,Private Villas in Kalyan,Villas near Kalyan"
        />
        <link rel="canonical" href="https://greenhillvilla.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Villas in Kalyan | Green Hill Villas" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://greenhillvilla.in/"/>
        <meta property="og:description" content="Discover the epitome of luxury living with our selection of the Best Villas in Kalyan. Immerse yourself in unparalleled comfort ." />
        <meta property="og:image" content="https://greenhillvilla.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>


            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='HomeSection1'>
                <div className='Home-banner'>
                    <NavBarComponent />
                    <div className='Home-banner-text'>
                        <div className='HB-Text'>
                            <Zoom top>
                                <h1>Best Villas in Kalyan </h1>
                            </Zoom>
                            <Fade bottom>
                                <p>Let the sea set you free.</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////////// section 2 /////////////////////////////////// */}

            <section className='HomeSection2'>
                <div className='container'>
                    <div className='Section2-Details'>
                        <div className='Section2-Image'>
                            <Zoom>
                                <div className='TreeImage'>
                                    <img src={Tree} alt="Tree" />
                                </div>
                            </Zoom>
                        </div>
                        <div className='Section2-title'>
                            <Flip left cascade>
                                <h3>A <strong>perfect-getaway</strong> to escape the ordinary, everyday life.</h3>
                            </Flip>
                        </div>
                        <div className='Section2-description'>
                            <Zoom>
                                <p>
                                Escape to the serene embrace of Green Hill, the best Villas in Kalyan, where every moment is a perfect getaway. Nestled amidst lush hills, our villa offers an idyllic retreat with a private swimming pool, inviting you to unwind in luxury. Indulge in exquisite dining experiences, surrounded by nature's splendor, as our skilled chefs tantalize your taste buds with culinary delights. Whether you seek relaxation or adventure, our villa provides the perfect sanctuary for rejuvenation. Discover tranquility, comfort, and unparalleled hospitality at Green Hill, best Villas in Kalyan, where every stay is a cherished memory waiting to be made.

                                </p>
                            </Zoom>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////////////// Show Section /////////////////////////////////////////// */}

            <section className='SectionShow1'>
                <div className='ShowImage1'>
                    <img src={Show1} alt="Show Image" />
                </div>
            </section>

            {/* ////////////////////////////////////////// section 3 /////////////////////////////////////////// */}

            <section className='HomeSection3'>
                <div className='container'>
                    <div className='HomeFacility'>
                    <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <Flip left cascade>
                                <h3>Facility</h3>
                                <p> All Rooms include awesome amenities that will fit business to family or Couple travel !</p>
                                {/* <h2>Facility</h2> */}
                            </Flip>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
                    </div>
                        {/* <div className='HomeFacility-title'>
                            <Slide top cascade>
                                <h3>Facility</h3>
                            </Slide>
                        </div>
                        <div className='HomeFacility-show'>
                            <div className='HomeFacility-show-Image'>
                                <img src={divider} alt="Show Images Line" />
                            </div>
                        </div> */}

                        {/* <div className='HomeFacility-qoutes'>
                            <Zoom>
                                <p>All Rooms include awesome amenities that will fit business to family or</p>
                                <p>Couple travel !</p>
                            </Zoom>
                        </div> */}
                    </div>

                    <div className='HomeFacility-Main'>
                        <div className='row row-cols-lg-5 row-cols-md-3 row-cols-sm-2'>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <ImSpoonKnife />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>breakfast</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <FaWifi />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>free wifi</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <BsDisplay />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>TV</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <FaShower />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>Rain Dance</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <MdPool />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>pool</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <IoBed />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>a/c room</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <FaDharmachakra />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>Generator</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <GiRotaryPhone />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>Intercom</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <AiFillCar />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>Free Parking</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <MdFoodBank />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>restaurant</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            {/* ////////////////////////////////////////// section 4 /////////////////////////////////////////// */}

            <section className='HomeSection4'>
                <div className='container'>
                <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <Flip left cascade>
                                <h3>About Us</h3>
                                <p> Discover Our Tale</p>
                                {/* <h2>About Us</h2> */}
                            </Flip>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='HS4-AboutUs-Images-con'>
                                <div className='HS4-AboutUs-Images'>
                                    <img src={leftOverlay} alt="Pool With Villa" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='HS4-Aboutus'>
                                <div className='HS4-AboutUs-Title'>
                                    <Bounce left cascade>
                                        <h5>About Green Hill Villa's</h5>
                                    </Bounce>
                                </div>
                                <div className='HS4-AboutUs-head'>
                                    <Flip left cascade>
                                        <h3>luxury and best service</h3>
                                        <h3>Resort in the town</h3>
                                    </Flip>

                                    <p>
                                    Welcome to Green Hill, the best Villas in Kalyan, where luxury meets exceptional service in the heart of town. Our resort boasts unparalleled elegance and comfort, ensuring a memorable stay for every guest. Immerse yourself in opulent surroundings and indulge in top-notch amenities. From personalized service to exquisite dining options, we cater to your every need with unmatched attention to detail. Experience the epitome of hospitality at Green Hill Villa – your premier destination for a lavish retreat.
                                    </p>
                                </div>
                            </div>
                            <div className='HS4-fea'>
                                <div className='HS4-AboutUs-fea-Images'>
                                    <div className='HS4-fea-Images'>
                                        <img src={bedImage} alt="Villa Interior" />
                                    </div>
                                </div>
                                {/* <div className='HS4-AboutUs-fea-detail'>
                                    <div className='HS4-pacefull-con'>
                                        <Zoom>
                                            <div className='HS4-pacefull-Iamge-con'>
                                                <GiSpotedFlower />
                                            </div>
                                        </Zoom>
                                        <h6>Pacefull Place</h6>
                                        <p>
                                            Surrounded by the beach view sea sight resort is a boutique hotel where you can immerse in a unique relaxation experience and delight in touch with nature.
                                        </p>

                                    </div>
                                    <div className='HS4-pacefull-con'>
                                        <Zoom>
                                            <div className='HS4-pacefull-Iamge-con'>
                                                <FaLuggageCart />
                                            </div>
                                        </Zoom>
                                        <h6>Top Travel</h6>
                                        <p>
                                            You get the idea: there’s a lot to see and do hereabouts. We are your travel partner for exploring the beauty of nature. as more and more venues have opened, opportunities for fun and adventure abound.
                                        </p>

                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>

                </div>
            </section>


            {/* ////////////////////////////////////////// section 5 /////////////////////////////////////////// */}

            <section className='HomeSection5'>
                <div className='HomeSection5-head-info'>
                <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <Flip left cascade>
                                <h3>Make your stay</h3>
                                <p>memorabel</p>
                                {/* <h2>stay</h2> */}
                            </Flip>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
                    </div>
                    {/* <div className='HS5-main-title'>
                        <Flip left cascade>
                            <h4>Make your stay</h4>
                            <h4>memorabel</h4>
                            
                        </Flip>
                    </div> */}
                    {/* <div className='HomeFacility-show'>
                        <div className='HomeFacility-show-Image'>
                            <img src={divider} alt="One Line Show Image" />
                        </div>
                    </div> */}
                    <div className='HS5-main-title-para'>
                        <Bounce left cascade>
                            <p>Best moment of your life</p>
                        </Bounce>
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Zoom>
                                <div className='HS5-left-Image'>
                                <div class="aboutImg">
                                            <img src={EnjoyFriend1} alt="garden lights" />
                                        </div>
                                    {/* <video className='videoTag' autoPlay loop muted>
                                        <source src={Video} type='video/mp4' />
                                    </video> */}
                                </div>
                            </Zoom>
                            <div className='HS5-left'>
                                <div className='HS5-with-partner'>
                                    <h4>
                                        Enjoy with your family
                                    </h4>
                                    <p>
                                        In our resort, you can enjoy yourself with your family. It’s beautiful all here, so you can comfortably soak up the stunning outdoor scenery all season long. Plus, you can enjoy unique events with the locals. Start planning your trip today and take advantage of deals and specials on holiday rental properties.
                                    </p>
                                </div>
                                <div className='HS5-with-friends'>
                                    <h4>
                                        Enjoy with your friends
                                    </h4>
                                    <p>
                                        Open all four seasons — summer, fall, winter, and spring… enjoy the beautiful area with friends any time of year.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='HS5-right'>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="aboutImg firstImage">
                                            <img src={Parasailing} alt="restaurant" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="aboutImg">
                                            <img src={Enjoypartner3} alt="garden lights" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="aboutImg thirdImage">
                                            <img src={EnjoyFriend2} alt="garden View" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="aboutImg lastImage">
                                            <img src={Enjoypartner2} alt="room" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ////////////////////////////////////////// section 6 /////////////////////////////////////////// */}

            <section className='HomeSection6'>
                <div className='HomeSection6-head-info'>
                <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <Flip left cascade>
                                <h3>Villas</h3>
                                <p>Luxury Hideaway Retreat</p>
                                {/* <h2 className='text-black'>ROOMS'S</h2> */}
                            </Flip>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
                    </div>
                {/* <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <Flip left cascade>
                                <h3>ROOMS'S</h3>
                                <p>ONE OF THE BEST PLACES TO TASTE.. ANYTHING</p>
                                <h2>ROOMS'S</h2>
                            </Flip>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className='container'>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        // navigation={true}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>   <div className='row'>
                            <div className='col-md-6'>
                                <div className='HS6-RoomDetail'>
                                    <div className='HS6-RoomDetail-description'>
                                    <h3>4BH Private pool villa  </h3>
                                        <h6>20,000/- for 12 Person Above 12 Person 800/- Extra</h6>
                                    </div>
                                    <div className='HS6-Facility-logo-con'>
                                        <div className='HS6-Icons'>
                                            <BsDisplay />
                                        </div>
                                        <div className='HS6-Icons'>
                                            <IoBed />
                                        </div>
                                        <div className='HS6-Icons'>
                                            <FaWifi />
                                        </div>
                                    </div>
                                    <div className='HS6-RoomDetail-para'>
                                        <p>
                                        Discover unparalleled luxury and privacy at Green Hill Villas 4bh villa with a private pool. Nestled amidst scenic landscapes, our villa offers the perfect blend of comfort and tranquility. Enjoy exclusive amenities and personalized service, ensuring a memorable retreat for you and your loved ones. Experience the essence of serenity at Green Hill Villa.
                                        </p>
                                    </div>

                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div className='HS6-roomImage'>
                                    <img src={fourBh} alt="Family Room" />
                                </div>
                            </div>

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   <div className='row'>
                            <div className='col-md-6'>
                                <div className='HS6-RoomDetail'>
                                    <div className='HS6-RoomDetail-description'>
                                    <h3>4BH Villa with Common pool</h3>
                                        <h6>15,000/- for 12 Person Above 12 Person 800/- Extra charges</h6>
                                    </div>
                                    <div className='HS6-Facility-logo-con'>
                                        <div className='HS6-Icons'>
                                            <BsDisplay />
                                        </div>
                                        <div className='HS6-Icons'>
                                            <IoBed />
                                        </div>
                                        <div className='HS6-Icons'>
                                            <FaWifi />
                                        </div>
                                    </div>
                                    <div className='HS6-RoomDetail-para'>
                                        <p>
                                        Experience blissful living at Green Hill Villas 3bh and 4bh with access to a common pool. Surrounded by picturesque vistas, our villas offer a harmonious blend of relaxation and community. Enjoy modern amenities and warm hospitality, creating cherished memories with family and friends. Embrace the essence of leisure at Green Hill, the best Villas in Kalyan.                                        </p>
                                    </div>

                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div className='HS6-roomImage'>
                                    <img src={leftOverlay} alt=" marter bed" />
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   <div className='row'>
                            <div className='col-md-6'>
                                <div className='HS6-RoomDetail'>
                                    <div className='HS6-RoomDetail-description'>
                                    <h3>3BH Villa with Common Pool</h3>
                                        <h6>12,000/- for 9 Person Above 9 Person 800/- Extra charges</h6>
                                    </div>
                                    <div className='HS6-Facility-logo-con'>
                                        <div className='HS6-Icons'>
                                            <BsDisplay />
                                        </div>
                                        <div className='HS6-Icons'>
                                            <IoBed />
                                        </div>
                                        <div className='HS6-Icons'>
                                            <FaWifi />
                                        </div>
                                    </div>.
                                    <div className='HS6-RoomDetail-para'>
                                        <p>
                                        Indulge in the serene lifestyle of Green Hill Villas, featuring spacious 3bh and 4bh accommodations alongside a shared pool. Enveloped by breathtaking scenery, our villas provide a seamless balance of tranquility and camaraderie. Revel in contemporary comforts and genuine hospitality, crafting unforgettable moments with loved ones. Experience pure leisure at Green Hill, your premier choice for villas in Kalyan.
                                        </p>
                                    </div>

                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div className='HS6-roomImage'>
                                    <img src={ThreeBh} alt=" marter bed" />
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>




            {/* ///////////////////////////////////// restaurant Section ///////////////////////////////////// */}

            <section className='ResturantSection2'>
                <div className='container'>
                    <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <Flip left cascade>
                                <h3>Green Hill Villa's</h3>
                                <p>ONE OF THE BEST PLACES TO TASTE.. ANYTHING</p>
                                {/* <h2>resturant</h2> */}
                            </Flip>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='EventSection2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Slide left>
                                <div className='HomeResImage'>
                                    <div className='EventImages'>
                                        <img src={HomeResturant} alt="open restaurants" />
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-6'>
                            <div className='Events_details-Con'>
                                <Slide right>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>WE GUARANTEE</h3>
                                            <h2>A REMARKABLE EXPERIENCE</h2>
                                            <p>Get cosy with these re-imagined classics on our new Fall Features menu, the restaurant offers stunning views along with a menu that offers local flair along with regional dishes.
                                            </p>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </section>


            {/* ////////////////////////////////////////// section 7 /////////////////////////////////////////// */}

            {/* <section className='HomeSection7'>
                <div className='HomeSection7-head-info'>
                    <div className='HS7-main-title'>
                        <Flip left cascade>
                            <h4>Near By activity</h4>
                        </Flip>
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>

                        <div className='col-md-3'>
                            <div className='HS7-Activity-fist HS7firstImage '>
                                <Zoom>
                                    <div className='HS7-activity-Name'>
                                        <h5>Jet Skiing</h5>
                                    </div>
                                    <div className='HS7-activity-Image'>
                                        <img src={JetSkiing} alt="Jet Skiing" />
                                    </div>
                                </Zoom>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='HS7-Activity-fist'>
                                <Zoom>
                                    <div className='HS7-activity-Name'>
                                        <h5>Parasailing</h5>
                                    </div>
                                    <div className='HS7-activity-Image'>
                                        <img src={Parasailing} alt="Parasailing" />
                                    </div>
                                </Zoom>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='HS7-Activity-fist HS7firstImage'>
                                <Zoom>
                                    <div className='HS7-activity-Name'>
                                        <h5>ATV Ride</h5>
                                    </div>
                                    <div className='HS7-activity-Image'>
                                        <img src={FlyboardFlying} alt="ATV Ride" />
                                    </div>
                                </Zoom>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='HS7-Activity-fist'>
                                <Zoom>
                                    <div className='HS7-activity-Name'>
                                        <h5>banana ride</h5>
                                    </div>
                                    <div className='HS7-activity-Image'>
                                        <img src={bananaride} alt="banana ride" />
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* ////////////////////////////////////////// section 8 /////////////////////////////////////////// */}

            {/* ///////////////////////////////////////////  review dection //////////////////////////////////////////// */}

            <section className="cutomerReview" id="cutomerReview">
                <div className='container'>
                <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <Flip left cascade>
                                <h3>What our Customers Say</h3>
                                <p>Voices of Satisfaction</p>
                                <h2 className='text-black'>REVIEW</h2>
                            </Flip>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
                    </div>
                    <ReviewsSwaiper />
                </div>
            </section>

            <Footer />




        </>
    )
}

export default Homepage;    
