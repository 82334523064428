import React from "react";
import NavBarComponent from "../Components/NavBarComponent";
import "../CSS/AboutUS.css";
import MetaTags from 'react-meta-tags';

import ShowImage01 from "../Images/divider.png";
// section 2 Images

import leftOverlay from "../greenHillVillas-webp/greenHillVilla(64).webp";
import bedImage from "../greenHillVillas-webp/greenHillVilla(50).webp";

import { GiSpotedFlower } from "react-icons/gi";
import { FaLuggageCart } from "react-icons/fa";
import Footer from "../Components/Footer";

import Bounce from "react-reveal/Bounce";
import Flip from "react-reveal/Flip";
import Zoom from "react-reveal/Zoom";
import LightSpeed from "react-reveal/LightSpeed";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

import ABImage01 from "../greenHillVillas-webp/greenHillVilla(24).webp";
import ABImage02 from "../greenHillVillas-webp/greenHillVilla(26).webp";
import ABImage03 from "../greenHillVillas-webp/greenHillVilla(44).webp";
import ABImage04 from "../greenHillVillas-webp/greenHillVilla(14).webp";
// import { MetaTags } from 'react-meta-tags';

const AboutUsPage = () => {
  return (
    <>
    <MetaTags>
      <title>Best Luxury Villas in Kalyan with Pool | Green Hill Villas</title>
        <meta title="Best Luxury Villas in Kalyan with Pool | Green Hill Villas" />
        <meta
          name="description"
          content="Indulge in opulence with our selection of the Best Luxury Villas in Kalyan Private Pools. Experience unparalleled comfort and sophistication."
        />
        <meta
          name="keywords"
          content="Best Villas in Kalyan ,Best Luxury Villas in Kalyan with Pool,Villas for Family in Kalyan,4bh Private Villas in Kalyan,Villas with Dining Services in Kalyan,Private Villas in Kalyan,Villas near Kalyan"
        />
        <link rel="canonical" href="https://greenhillvilla.in/luxury-villas-kalyan-pool" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Luxury Villas in Kalyan with Pool | Green Hill Villas" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://greenhillvilla.in/luxury-villas-kalyan-pool"/>
        <meta property="og:description" content="Indulge in opulence with our selection of the Best Luxury Villas in Kalyan Private Pools. Experience unparalleled comfort and sophistication." />
        <meta property="og:image" content="https://greenhillvilla.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>

      {/* /////////////////////////////// section 1 /////////////////////////////////// */}
      <section className="AboutUsSection1">
        <div className="AboutUs-banner">
          <NavBarComponent />
          <div className="AboutUs-banner-text">
            {/* <Slide left>
                        <div className='Text'>
                            <h4>An actor has to burn inside with an outer ease.</h4>
                            </div>
                        </Slide> */}
            <div className="ABS1-Text">
              <Zoom top>
                <h1>Best Luxury Villas in Kalyan with Pool</h1>
              </Zoom>
              <Fade bottom>
                <p>It's Bad Manner To Keep A Vacation Wating</p>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      {/* /////////////////////////////// section 2 /////////////////////////////////// */}

      <section className="HomeSection4">
        <div className="Resturant-Details">
          <div className="Resturant-Title">
            <Flip left cascade>
              <h3>About Us</h3>
              <p> Our Story Unveiled Here</p>
              {/* <h2>About Us</h2> */}
            </Flip>
          </div>
          <div className="Showcon">
            <div className="Show-Image">
              <img src={ShowImage01} alt="Decoration Image" />
            </div>
          </div>
        </div>
        {/* <div className='AB-title'>
                    <LightSpeed left cascade>
                        <h1>About Us</h1>
                    </LightSpeed>
                    <Zoom>
                        <h2>About Us</h2>
                    </Zoom>
                </div> */}
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="HS4-AboutUs-Images-con">
                <div className="HS4-AboutUs-Images">
                  <img src={leftOverlay} alt="villa With Pool" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="HS4-Aboutus">
                <div className="HS4-AboutUs-Title">
                  <Bounce left cascade>
                    <h5>About Green Hill Villa's</h5>
                  </Bounce>
                </div>
                <div className="HS4-AboutUs-head">
                  <Flip left cascade>
                    <h3>luxury and best service</h3>
                    <h3>villa in the town</h3>
                  </Flip>
                  <p>
                    Welcome to Green Hill, the best luxury villas in Kalyan with
                    pool, your ultimate destination for luxurious retreats
                    amidst breathtaking landscapes. Nestled in the heart of
                    natural beauty, our resort boasts an array of offerings
                    tailored to elevate your experience.
                  </p>
                </div>
              </div>
              <div className="HS4-fea">
                <div className="row">
                  <div className="col-md-12">
                    <div className="HS4-AboutUs-fea-Images">
                      <div className="HS4-fea-Images">
                        <img src={bedImage} alt="Singel Room" />
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-md-6'>
                                        <div className='HS4-AboutUs-fea-detail'>
                                            <div className='HS4-pacefull-con'>
                                                <Zoom>
                                                    <div className='HS4-pacefull-Iamge-con'>
                                                        <GiSpotedFlower />
                                                    </div>
                                                </Zoom>
                                                <h6>Pacefull Place</h6>
                                                <p>
                                                    Surrounded by the beach view sea Green Hill Villa's is a boutique hotel where you can immerse in a unique relaxation experience and delight in touch with nature.
                                                </p>

                                            </div>
                                            <div className='HS4-pacefull-con'>
                                                <Zoom>
                                                    <div className='HS4-pacefull-Iamge-con'>
                                                        <FaLuggageCart />
                                                    </div>
                                                </Zoom>
                                                <h6>Top Travel</h6>
                                                <p>
                                                    You get the idea: there’s a lot to see and do hereabouts. We are your travel partner for exploring the beauty of nature. as more and more venues have opened, opportunities for fun and adventure abound.
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* /////////////////////////////// section 3 /////////////////////////////////// */}

      <section className="ABoutUsSection">
        <div className="container">
          <div className="villainfo">
            <LightSpeed left cascade>
              <h4>About Villa</h4>
            </LightSpeed>
            <Zoom>
              <p>
                Choose from our exquisite selection of the best luxury villas in
                Kalyan with pool, including spacious 3-bedroom and 4-bedroom
                options, each equipped with modern amenities and stunning views.
                {/* And enjoy our nearby attraction Nagaon beach which is just 300 meters away from our resort. Nagaon beach is popular mainly because of its cleanliness, and water sports activities. */}
              </p>
            </Zoom>
          </div>
          <p className="d-none">
            Whether you seek a cozy escape or a grandeur getaway, our villas
            provide the perfect sanctuary for relaxation and rejuvenation. With
            private pools and access to a common pool, every moment at Green
            Hill Villa is infused with tranquility and luxury. Dining: Embark on
            a culinary journey at our dining venues, where our talented chefs
            craft exquisite dishes inspired by local flavors and international
            cuisines. Indulge in gourmet delights amidst the serene surroundings
            of the best luxury villas in Kalyan with pool, whether dining al
            fresco by the pool or in the elegant ambiance of our restaurant.
            From hearty breakfasts to decadent dinners, every meal at Green Hill
            Villa is a feast for the senses. Events: Celebrate life's special
            moments in style at Green Hill, the best luxury villas in Kalyan
            with pool, where we offer versatile event spaces and impeccable
            service to ensure your gatherings are unforgettable. Whether you're
            planning a romantic wedding, a corporate retreat, or a family
            reunion, our dedicated team will assist you in every step of the
            planning process. From intimate ceremonies to grand receptions, our
            picturesque setting provides the perfect backdrop for your event.
            Activities: Immerse yourself in a wealth of recreational activities
            during your stay at Green Hill, the best luxury villas in Kalyan
            with pool. Explore the surrounding nature trails, indulge in
            rejuvenating spa treatments, or partake in exhilarating water sports
            at the nearby beach. For those seeking cultural experiences, we
            offer guided tours to local attractions and opportunities to engage
            with the vibrant community. Hospitality: At Green Hill, the best
            luxury villas in Kalyan with pool, hospitality is our hallmark. Our
            attentive staff is committed to exceeding your expectations,
            ensuring that every aspect of your stay is seamless and memorable.
            Whether you require personalized concierge services or special
            arrangements for your celebrations, we are dedicated to making your
            time with us truly exceptional. Experience the epitome of luxury and
            hospitality at Green Hill Villa, where every moment is a cherished
            memory waiting to be made.
          </p>

          <div className="AboutVillaImages">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-3">
                <div className="VillaImage">
                  <img src={ABImage04} alt="Villa" />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-3">
                <div className="VillaImage">
                  <img src={ABImage01} alt="villa restaurant" />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-3">
                <div className="VillaImage">
                  <img src={ABImage03} alt="Night View Villa" />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-3">
                <div className="VillaImage">
                  <img src={ABImage02} alt="Near Pool villa Room" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AboutUsPage;
