import React, { useRef, useState } from "react";
import MetaTags from 'react-meta-tags';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import NavBarComponent from '../Components/NavBarComponent';
import "../CSS/Restaurants.css";

import offerimage1 from "../Images/Restaurants/offer-image-1.jpg";
import offerimage2 from "../Images/Restaurants/offer-image-2.jpg";
import offerimage3 from "../Images/Restaurants/offer-image-3.jpg";

import AnimatedImage01 from "../Images/Restaurants/bg-2.png";
import AnimatedImage02 from "../Images/Restaurants/bg-1.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";


////////////// DISH /////////////////

import NonvegThali from "../Images/Dish/aradhana.jpg";
import VegThali from "../Images/Dish/veg Thali.jpg";
import MisalPav from "../Images/Dish/Misal-pav.jpg";
import PaneerSeekKabab from "../Images/Dish/Chicken-Seek-Kabab.jpg";
import harabharakabab from "../Images/Dish/hara-bhara-kabab-recipe-1.jpg";
import chicken65 from "../Images/Dish/chicken-65.jpg";
import ProunceDIsh from "../Images/Dish/Prounce DIsh.jpg";
import CrabGravy from "../Images/Dish/Singapore-Chilli-Crab_4.webp";
import VegPulaoRice from "../Images/Dish/Veg Pulao Rice.jpg";

import FourDish from "../Images/Dish/AboutUS.png";

import Menu1 from "../Images/Dish/Menu 1.jpg";
import Menu2 from "../Images/Dish/Menu 2.jpg";
import Menu3 from "../Images/Dish/Menu 3.jpg";
import Menu4 from "../Images/Dish/Menu 4.jpg";
import Menu5 from "../Images/Dish/Menu 5.jpg";
import Menu6 from "../Images/Dish/Menu 6.jpg";
import Menu7 from "../Images/Dish/Menu 7.jpg";

import Footer from "../Components/Footer";
// import { MetaTags } from 'react-meta-tags';


const Restaurants = () => {
    return (
        <>
            <MetaTags>
      <title>Villas with Dining Services in Kalyan | Green Hill Villas</title>
        <meta title="Villas with Dining Services in Kalyan | Green Hill Villas" />
        <meta
          name="description"
          content="Indulge in opulent living at our Villas with Dining Services in Kalyan. Enjoy gourmet meals and lavish comforts amidst serene surroundings."
        />
        <meta
          name="keywords"
          content="Best Villas in Kalyan ,Best Luxury Villas in Kalyan with Pool,Villas for Family in Kalyan,4bh Private Villas in Kalyan,Villas with Dining Services in Kalyan,Private Villas in Kalyan,Villas near Kalyan"
        />
        <link rel="canonical" href="https://greenhillvilla.in/villas-dining-services-kalyan" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Villas with Dining Services in Kalyan | Green Hill Villas" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://greenhillvilla.in/villas-dining-services-kalyan"/>
        <meta property="og:description" content="Indulge in opulent living at our Villas with Dining Services in Kalyan. Enjoy gourmet meals and lavish comforts amidst serene surroundings." />
        <meta property="og:image" content="https://greenhillvilla.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <p className="d-none">
            Nestled amidst lush greenery, our Villas with dining services in Kalyan, offer a perfect blend of ambiance and delectable cuisine. Whether you choose to dine al fresco on the terrace, under the canopy of stars, or in the elegant indoor setting, each meal is an opportunity to savor the flavors of the region and beyond.

Our talented chefs curate menus that showcase the freshest local ingredients, ensuring a farm-to-table experience that bursts with flavor and authenticity. From traditional delicacies to international favorites, there's something to please every palate. Indulge in mouthwatering dishes crafted with care and precision, accompanied by an extensive selection of wines and spirits to complement your meal.
In addition to our exquisite dining offerings, we also provide customizable catering services for special events and occasions. Whether you're hosting a wedding, corporate retreat, or private celebration, our team works closely with you to create a bespoke menu that exceeds expectations and leaves a lasting impression on your guests.

Experience the art of dining at Green Hill Villas with dining services in Kalyan, where every meal is a celebration of flavor, creativity, and hospitality. Join us and embark on a culinary journey that delights the senses and nourishes the soul, leaving you craving for more with each visit.

            </p>
            <section className='RestaurantSection1'>
                <div className='Restaurant-banner'>
                    <NavBarComponent />
                    <div className='Restaurant-banner-text'>
                        <div className='AS1-Text'>
                            <Zoom top>
                                <h1>Villas with Dining Services in Kalyan </h1>
                            </Zoom>
                            <Fade bottom>
                                <p>We make planning easy, so you can focus on celebrating!</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////////// section 2 /////////////////////////////////// */}

            <section className='RestaurantsSection2'>
                <div className='RestaurantsSection2-con'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-md-4 order-2 order-sm-1'>
                                <div className='Breakfast-con'>
                                    <div className='Breakfast-mid'>
                                        <div className='Breakfast'>
                                            <img src={offerimage1} alt="" />
                                        </div>
                                        <div className='Breakfast-Name'>
                                            <h5>Breakfast</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4 order-1  order-sm-2'>
                                <div className='Breakfast-con'>
                                    <div className='Breakfast-mid'>
                                        <div className='Breakfast-Details-con'>
                                            <h5>FLAVORS FOR ROYALTY</h5>
                                            <h2>We Offer Top Notch</h2>
                                            <p>
                                            Welcome to the dining experience at Green Hill Villas with dining services in Kalyan, where every meal is a journey of culinary delight amidst the backdrop of nature's serenity. Our dining page reflects our commitment to providing guests with exceptional gastronomic experiences that tantalize the taste buds and nourish the soul.

                                            </p>
                                        </div>
                                        <div className='Breakfast-Image'>
                                            <div className='Breakfast'>
                                                <img src={offerimage2} alt="" />
                                            </div>
                                        </div>
                                        <div className='Breakfast-Name'>
                                            <h5>Lunch</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4 order-3 order-sm-3'>
                                <div className='Breakfast-con'>
                                    <div className='Breakfast-mid'>
                                        <div className='Breakfast'>
                                            <img src={offerimage3} alt="" />
                                        </div>
                                        <div className='Breakfast-Name'>
                                            <h5>Dinner</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='Animated-Image-con1'>
                    <div className='Animated-Image1'>
                        <img src={AnimatedImage01} alt="" />
                    </div>
                </div>
                <div className='Animated-Image-con2'>
                    <div className='Animated-Image2'>
                        <img src={AnimatedImage02} alt="" />

                    </div>

                </div> */}
            </section>

            {/* /////////////////////////////// section 4 /////////////////////////////////// */}

            <section className="RestaurantsSection4">
                <div className="container">
                    <div className="RS-4-deatils">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="RS-4-Image-con">
                                    <div className="RS-4-Image">
                                        <img src={FourDish} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="RS-4-Details">
                                    <div className="RS-Info">
                                        <h5>About Restaurant</h5>
                                        <h2>Traditional & Modern Service</h2>
                                        <p>
                                        At Green Hill Villas with dining services in Kalyan, dining is not just about nourishment; it's about creating memories. Whether it's a romantic dinner for two, a family celebration, or a gathering of friends, our attentive staff ensures that every moment is unforgettable. Immerse yourself in the warm hospitality and relaxed ambiance as you savor each bite and sip, surrounded by the natural beauty of our surroundings.                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>


            {/* ///////////////////////////////////////////////////////////////// */}

            {/* /////////////////////////////// section 3 /////////////////////////////////// */}
            <section className='RestaurantsSection3'>
                <div className='container'>
                    <div className='RS3-Details'>
                        <h6>SPECIAL OFFER</h6>
                        <h2>Best Specialties</h2>
                    </div>
                    <div className='RS3-Swaiper'>
                        {/* <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>Slide 1</SwiperSlide>
                            <SwiperSlide>Slide 2</SwiperSlide>
                            <SwiperSlide>Slide 3</SwiperSlide>
                            <SwiperSlide>Slide 4</SwiperSlide>
                            <SwiperSlide>Slide 5</SwiperSlide>
                            <SwiperSlide>Slide 6</SwiperSlide>
                            <SwiperSlide>Slide 7</SwiperSlide>
                            <SwiperSlide>Slide 8</SwiperSlide>
                            <SwiperSlide>Slide 9</SwiperSlide>
                        </Swiper> */}

                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="Swiper-con">
                                    <div className="Swaiper-Image">
                                        <img src={MisalPav} alt="" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Misal Pav</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="Swiper-con topI">
                                    <div className="Swaiper-Image">
                                        <img src={VegThali} alt="" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Veg Thali</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="Swiper-con">
                                    <div className="Swaiper-Image">
                                        <img src={harabharakabab} alt="" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Veg Harabhara Kabab</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="Swiper-con topI">
                                    <div className="Swaiper-Image">
                                        <img src={PaneerSeekKabab} alt="" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Paneer Seck Kabab</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="Swiper-con">
                                    <div className="Swaiper-Image">
                                        <img src={NonvegThali} alt="" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Non-Veg Thali</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="Swiper-con topI">
                                    <div className="Swaiper-Image">
                                        <img src={chicken65} alt="" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>chicken-65</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="Swiper-con">
                                    <div className="Swaiper-Image">
                                        <img src={ProunceDIsh} alt="" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Prawns Dish</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="Swiper-con topI">
                                    <div className="Swaiper-Image">
                                        <img src={CrabGravy} alt="" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Crab Gravy</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="Swiper-con">
                                    <div className="Swaiper-Image">
                                        <img src={VegPulaoRice} alt="" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Veg Pulao Rice</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>


            {/* /////////////////////////// Section 5 /////////////////////////////// */}

            {/* <section className="RestaurantsSection5">
                <div className="Menu-Details">
                    <h5>SPECIAL SELECTION</h5>
                    <h2>Delicious Menu</h2>
                </div>
                <div className="container">
                    <div className="RS5-menu">
                        <div className="RS5-Swiper">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 50,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                            >

                                <SwiperSlide>
                                    <div className="Swiper-Menu">
                                        <div className="Swiper-Menu-Image">
                                            <img src={Menu1} alt="" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="Swiper-Menu">
                                        <div className="Swiper-Menu-Image">
                                            <img src={Menu2} alt="" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="Swiper-Menu">
                                        <div className="Swiper-Menu-Image">
                                            <img src={Menu3} alt="" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="Swiper-Menu">
                                        <div className="Swiper-Menu-Image">
                                            <img src={Menu4} alt="" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="Swiper-Menu">
                                        <div className="Swiper-Menu-Image">
                                            <img src={Menu5} alt="" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="Swiper-Menu">
                                        <div className="Swiper-Menu-Image">
                                            <img src={Menu6} alt="" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="Swiper-Menu">
                                        <div className="Swiper-Menu-Image">
                                            <img src={Menu7} alt="" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>

                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </>
    )
}

export default Restaurants