import React, { useState,useRef } from 'react';
import MetaTags from 'react-meta-tags';

import "../CSS/ContactUs.css";
import NavBarComponent from '../Components/NavBarComponent';
import $ from "jquery"
import ShowImage01 from "../Images/divider.png"
///////
import Roll from 'react-reveal/Roll';
import Rotate from 'react-reveal/Rotate';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';



import { MdLocationPin } from 'react-icons/md';
import { HiMail } from 'react-icons/hi';
import { CgMediaPodcast } from 'react-icons/cg';
import { IoCall } from 'react-icons/io5';

import { RiWhatsappLine } from 'react-icons/ri';
import { BsInstagram } from 'react-icons/bs';
import { AiOutlineTwitter } from 'react-icons/ai';
import { GrFacebookOption } from 'react-icons/gr';


import Footer from '../Components/Footer';

const ContactUs = () => {
 // email
 const formRef = useRef(null);
 const [custName, setcustName] = useState("");
 const [custContact, setCustContact] = useState("");
 const [custMessage, setCustMessage] = useState("");
 const [custEmail, setCustEmail] = useState("");
 const [custSubject, setCustSubject] = useState("");
 const [formError, setFormError] = useState(false);

 const ContactEnquiry = (e) => {
   e.preventDefault();

   // Validate form fields
   if (!custName || !custContact || !custMessage || !custEmail) {
     setFormError(true);
     return;
   }

   // Reset form error state
   setFormError(false);

   // Your form submission logic goes here
   var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Green Hill Villa's Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Green Hill Villa's</p></div></body></html>`;

   $.post(
     "https://skdm.in/server/v1/send_lead_mail.php",
     {
       toEmail: "greenhillvillas400@gmail.com",
       fromEmail: "skdmlead@gmail.com",
       bccMail: "skdmlead@gmail.com",
       mailSubject: "New Customer Lead",
       mailBody: body,
       accountName: "green hill villa",
       accountLeadSource: "https://skdm.in",
       accountLeadName: custName,
     },
     function (dataa, status) {
       console.log("data :" + dataa);
     }
   );

   // Reset the form
   if (formRef.current) {
     formRef.current.reset();
   }

   alert("Your Form has Submitted. Our team will contact you soon.");

 };



  return (
    <>
<MetaTags>
      <title>Villas near Kalyan | Green Hill Villas</title>
        <meta title="Villas near Kalyan | Green Hill Villas" />
        <meta
          name="description"
          content="Escape to luxury with our collection of stunning Villas near Kalyan. Find serenity, comfort, and breathtaking views just a stone's throw away from Kalyan."
        />
        <meta
          name="keywords"
          content="Best Villas in Kalyan ,Best Luxury Villas in Kalyan with Pool,Villas for Family in Kalyan,4bh Private Villas in Kalyan,Villas with Dining Services in Kalyan,Private Villas in Kalyan,Villas near Kalyan"
        />
        <link rel="canonical" href="https://greenhillvilla.in/villas-near-kalyan" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Villas near Kalyan | Green Hill Villas" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://greenhillvilla.in/villas-near-kalyan"/>
        <meta property="og:description" content="Escape to luxury with our collection of stunning Villas near Kalyan. Find serenity, comfort, and breathtaking views just a stone's throw away from Kalyan." />
        <meta property="og:image" content="https://greenhillvilla.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>

      {/* /////////////////////////////// section 1 /////////////////////////////////// */}
      <section className='ContactUsSection1'>
        <div className='ContactUs-banner'>
          <NavBarComponent />
          <div className='ContactUs-banner-text'>
            {/* <Slide left>
                        <div className='Text'>
                            <h4>An actor has to burn inside with an outer ease.</h4>
                            </div>
                        </Slide> */}
            <div className='CUS1-Text'>
              <Zoom top>
                <h1>Villas near Kalyan</h1>
              </Zoom>
              <Fade bottom>
                <p>The days are long, but the years are short.</p>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      {/* /////////////////////////////// section 2 /////////////////////////////////// */}

      {/* ///////////////////////////////// section 2 /////////////////////////////////////////// */}


      <section className="Contact-Us-2">
        <div className='container'>
          <div className="row">
          <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            {/* <Flip left cascade> */}
                                <h3>Office Details</h3>
                                <p> Where Work Comes Alive</p>
                                {/* <h2> Details</h2> */}
                            {/* </Flip> */}
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
                    </div>
            {/* <div className='row'>
              <div className='Office-Details'>
                <h3>
                  <Rotate top left cascade>
                    Office Details
                  </Rotate>

                </h3>
              </div>
            </div> */}
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='Contact-info'>
                <div className='Contact-info-item'>
                  <div className='Contact-info-icon'>
                    <Zoom cascade>
                      <MdLocationPin />
                    </Zoom>
                  </div>
                  <div className='Contact-info-text'>
                    <h2>Address</h2>
                    <Fade cascade>
                      <span>Ganesh Kot Farmhouse, </span>
                      <span> Murbad Road,Bapsai, Kalyan,</span>
                      <span> Maharashtra 421103</span>
                    </Fade>

                  </div>
                </div>

              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='Contact-info'>
                <div className='Contact-info-item'>
                  <div className='Contact-info-icon'>
                    <Zoom cascade>
                      <HiMail />
                    </Zoom>
                  </div>
                  <div className='Contact-info-text'>

                    <h2>E-Mail</h2>
                    <Fade cascade>
                      <a style={{ color: "#000" }}
                        href="mailto:greenhillvillas400@gmail.com">greenhillvillas400@gmail.com</a>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='Contact-info'>
                <div className='Contact-info-item'>
                  <div className='Contact-info-icon'>
                    <Zoom cascade>
                      <CgMediaPodcast />
                    </Zoom>
                  </div>
                  <div className='Contact-info-text'>
                    <h2>Social Media</h2>
                    <Fade cascade>
                      <button className='Social-icon'><a  href="https://wa.me/8108181400"><RiWhatsappLine /></a></button>
                      <button className='Social-icon'><a  target="_blank" href='https://www.instagram.com/greenhillvillas0/'><BsInstagram /></a></button>
                      <button className='Social-icon'><a  target="_blank" href='https://www.facebook.com/greenhillvillas0/'><GrFacebookOption /></a></button>
                    </Fade>

                  </div>
                </div>

              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='Contact-info'>
                <div className='Contact-info-item'>
                  <div className='Contact-info-icon'>
                    <Zoom cascade>
                      <IoCall />
                    </Zoom>
                  </div>
                  <div className='Contact-info-text'>
                    <h2>Contact</h2>
                    <Fade cascade>
                      <span>8624000400 / </span><br></br>
                      <span>8108181400</span>
                    </Fade>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //////////////////////////// section 4 //////////////////////////////////////////////// */}

      <section className='ContactUs-form-Section-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5 col-sm-12'>
              <div className='contact-form-image'>
              <iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15064.276714542999!2d73.2596229!3d19.2793576!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be791c584d5f9d3%3A0x390e81b6d28fccbe!2sGreen%20Hill%20Villas!5e0!3m2!1sen!2sin!4v1710397764199!5m2!1sen!2sin"
  width="100%"
  height="100%"
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>
              </div>

            </div>
            <div className='col-md-7 col-sm-12'>
              <div className='Name-input'>
                <div className="row">
                <form
        method="post"
        ref={formRef}
        action=""
        id="contact-form"
      >
         <div className="col">
                    <input type="text" name="name" className="form-control custName" onChange={(e) => setcustName(e.target.value)} placeholder="First name" />
                  </div>
      </form>
                 
                </div>
              </div>

              <div className='Email'>
                <input type="email"  name="email" className="form-control custEmail" onChange={(e) => setCustEmail(e.target.value)} placeholder="Email" />
              </div>

              <div className='Phone-no'>
                <input type="number" name="contact" className="form-control custPhone" onChange={(e) => setCustContact(e.target.value)} placeholder="contact no" />
              </div>
              <div className='message'>
                <textarea type="text"  name="message" className="form-control custMessage" onChange={(e) => setCustMessage(e.target.value)} placeholder='message' />
              </div>
              {formError && (
          <p style={{ color: "red" }}>Please fill in all required fields</p>
        )}
              <button className='form-submit-button' onClick={ContactEnquiry}>Submit</button>
            </div>
          </div>
        </div>
      </section>

      <Footer />


    </>
  )
}

export default ContactUs;