
import Image01 from "../greenHillVillas-webp/greenHillVilla(1).webp";
import Image02 from "../greenHillVillas-webp/greenHillVilla(2).webp";
import Image03 from "../greenHillVillas-webp/greenHillVilla(3).webp";
import Image04 from "../greenHillVillas-webp/greenHillVilla(4).webp";
import Image05 from "../greenHillVillas-webp/greenHillVilla(5).webp";
import Image06 from "../greenHillVillas-webp/greenHillVilla(6).webp";
import Image07 from "../greenHillVillas-webp/greenHillVilla(7).webp";
import Image08 from "../greenHillVillas-webp/greenHillVilla(8).webp";
import Image09 from "../greenHillVillas-webp/greenHillVilla(9).webp";
import Image10 from "../greenHillVillas-webp/greenHillVilla(10).webp";
import Image11 from "../greenHillVillas-webp/greenHillVilla(11).webp";
import Image12 from "../greenHillVillas-webp/greenHillVilla(12).webp";
import Image13 from "../greenHillVillas-webp/greenHillVilla(13).webp";
import Image14 from "../greenHillVillas-webp/greenHillVilla(14).webp";
import Image15 from "../greenHillVillas-webp/greenHillVilla(15).webp";
// import Image16 from "../greenHillVillas-webp/greenHillVilla(16).webp";
import Image17 from "../greenHillVillas-webp/greenHillVilla(17).webp";
import Image18 from "../greenHillVillas-webp/greenHillVilla(18).webp";
import Image19 from "../greenHillVillas-webp/greenHillVilla(19).webp";
import Image20 from "../greenHillVillas-webp/greenHillVilla(20).webp";
import Image21 from "../greenHillVillas-webp/greenHillVilla(21).webp";
import Image22 from "../greenHillVillas-webp/greenHillVilla(22).webp";
import Image23 from "../greenHillVillas-webp/greenHillVilla(23).webp";
import Image24 from "../greenHillVillas-webp/greenHillVilla(24).webp";
// import Image25 from "../greenHillVillas-webp/greenHillVilla(25).webp";
import Image26 from "../greenHillVillas-webp/greenHillVilla(26).webp";
import Image27 from "../greenHillVillas-webp/greenHillVilla(27).webp";
import Image28 from "../greenHillVillas-webp/greenHillVilla(28).webp";
import Image29 from "../greenHillVillas-webp/greenHillVilla(29).webp";
import Image30 from "../greenHillVillas-webp/greenHillVilla(30).webp";
import Image31 from "../greenHillVillas-webp/greenHillVilla(31).webp";
import Image32 from "../greenHillVillas-webp/greenHillVilla(32).webp";
import Image33 from "../greenHillVillas-webp/greenHillVilla(33).webp";
import Image34 from "../greenHillVillas-webp/greenHillVilla(34).webp";
import Image35 from "../greenHillVillas-webp/greenHillVilla(35).webp";
import Image36 from "../greenHillVillas-webp/greenHillVilla(36).webp";
import Image37 from "../greenHillVillas-webp/greenHillVilla(37).webp";
import Image38 from "../greenHillVillas-webp/greenHillVilla(38).webp";
import Image39 from "../greenHillVillas-webp/greenHillVilla(39).webp";
import Image40 from "../greenHillVillas-webp/greenHillVilla(40).webp";
import Image41 from "../greenHillVillas-webp/greenHillVilla(41).webp";
import Image42 from "../greenHillVillas-webp/greenHillVilla(42).webp";
import Image43 from "../greenHillVillas-webp/greenHillVilla(43).webp";
import Image44 from "../greenHillVillas-webp/greenHillVilla(44).webp";
import Image45 from "../greenHillVillas-webp/greenHillVilla(45).webp";
import Image46 from "../greenHillVillas-webp/greenHillVilla(46).webp";
import Image47 from "../greenHillVillas-webp/greenHillVilla(47).webp";
import Image48 from "../greenHillVillas-webp/greenHillVilla(48).webp";
import Image49 from "../greenHillVillas-webp/greenHillVilla(49).webp";
import Image50 from "../greenHillVillas-webp/greenHillVilla(50).webp";
import Image51 from "../greenHillVillas-webp/greenHillVilla(51).webp";
import Image52 from "../greenHillVillas-webp/greenHillVilla(52).webp";
import Image53 from "../greenHillVillas-webp/greenHillVilla(53).webp";
import Image54 from "../greenHillVillas-webp/greenHillVilla(54).webp";
import Image55 from "../greenHillVillas-webp/greenHillVilla(55).webp";
import Image56 from "../greenHillVillas-webp/greenHillVilla(56).webp";
import Image57 from "../greenHillVillas-webp/greenHillVilla(57).webp";
import Image58 from "../greenHillVillas-webp/greenHillVilla(58).webp";
import Image59 from "../greenHillVillas-webp/greenHillVilla(59).webp";
import Image60 from "../greenHillVillas-webp/greenHillVilla(60).webp";
import Image61 from "../greenHillVillas-webp/greenHillVilla(61).webp";
import Image62 from "../greenHillVillas-webp/greenHillVilla(62).webp";
import Image63 from "../greenHillVillas-webp/greenHillVilla(63).webp";
import Image64 from "../greenHillVillas-webp/greenHillVilla(64).webp";
import Image65 from "../greenHillVillas-webp/greenHillVilla(65).webp";

const GalleryData = [
    {
        id: 1,
        Image: Image01, alt:"Private  in Kalyan"
    },
    {
        id: 2,
        Image: Image02, alt:"Private Villas in Kalyan"
    },
    {
        id: 3,
        Image: Image03, alt:" Villas in Kalyan"
    },
    {
        id: 4,
        Image: Image04, alt:"Private Villas "
    },
    {
        id: 5,
        Image: Image05, alt:"Private Villas in Kalyan"
    },
    {
        id: 6,
        Image: Image06, alt:" Villas in Kalyan"
    },
    {
        id: 7,
        Image: Image07, alt:"Private  in Kalyan"
    },
    {
        id: 8,
        Image: Image08, alt:"Private Villas "
    },
    {
        id: 9,
        Image: Image09, alt:"Private  in Kalyan"
    },
    {
        id: 10,
        Image: Image10, alt:" Villas in Kalyan"
    },
    {
        id: 11,
        Image: Image11, alt:"Private Villas "
    },
    {
        id: 12,
        Image: Image12, alt:"Private Villas in Kalyan"
    },
    {
        id: 13,
        Image: Image13, alt:"Private  in Kalyan"
    },
    {
        id: 14,
        Image: Image14, alt:"Private  in Kalyan"
    },
    {
        id: 15,
        Image: Image15, alt:" Villas in Kalyan"
    },
    // {
    //     id: 16,
    //     Image: Image16, alt:"Private Villas in Kalyan"
    // },
    {
        id: 17,
        Image: Image17, alt:" Villas in Kalyan"
    },
    {
        id: 18,
        Image: Image18, alt:"Private Villas in Kalyan"
    },
    { id: 19, Image: Image19, alt:" Villas in Kalyan"},
    { id: 20, Image: Image20, alt:" Villas in Kalyan"},
    { id: 21, Image: Image21, alt:"Private Villas "},
    { id: 22, Image: Image22, alt:"Private  in Kalyan"},
    { id: 23, Image: Image23, alt:"Private Villas in Kalyan"},
    { id: 24, Image: Image24, alt:"Private  in Kalyan"},
    // { id: 25, Image: Image25, alt:"Private Villas in Kalyan"},
    { id: 26, Image: Image26, alt:" Villas in Kalyan"},
    { id: 27, Image: Image27, alt:"Private Villas "},
    { id: 28, Image: Image28, alt:"Private  in Kalyan"},
    { id: 29, Image: Image29, alt:" Villas in Kalyan"},
    { id: 30, Image: Image30, alt:"Private Villas in Kalyan"},
    { id: 31, Image: Image31, alt:"Private  in Kalyan"},
    { id: 32, Image: Image32, alt:" Villas in Kalyan"},
    { id: 33, Image: Image33, alt:"Private Villas in Kalyan"},
    { id: 34, Image: Image34, alt:"Private Villas"},
    { id: 35, Image: Image35, alt:"Private Villas in Kalyan"},
    { id: 36, Image: Image36, alt:"Private in Kalyan"},
    { id: 37, Image: Image37, alt:" Villas in Kalyan"},
    { id: 38, Image: Image38, alt:"Private  in Kalyan"},
    { id: 39, Image: Image39, alt:"Private Villas in Kalyan"},
    // { id: 40, Image: Image40, alt:"Private Villas in Kalyan"},
    { id: 41, Image: Image41, alt:"Private Villas "},
    { id: 42, Image: Image42, alt:"Private Villas in Kalyan"},
    { id: 43, Image: Image43, alt:"Private Villas "},
    // { id: 44, Image: Image44, alt:"Private Villas in Kalyan"},
    // { id: 45, Image: Image45, alt:"Private Villas in Kalyan"},
    { id: 46, Image: Image46, alt:" Villas in Kalyan"},
    { id: 47, Image: Image47, alt:" Villas in Kalyan"},
    // { id: 48, Image: Image48, alt:"Private Villas in Kalyan"},
    // { id: 49, Image: Image49, alt:"Private Villas in Kalyan"},
    // { id: 50, Image: Image50, alt:"Private Villas in Kalyan"},
    { id: 51, Image: Image51, alt:"Private  in Kalyan"},
    { id: 52, Image: Image52, alt:"Private Villas "},
    // { id: 53, Image: Image53, alt:"Private Villas in Kalyan"},
    { id: 54, Image: Image54, alt:" Villas in Kalyan"},
    { id: 55, Image: Image55, alt:"Private  in Kalyan"},
    { id: 56, Image: Image56, alt:"Private  in Kalyan"},
    { id: 57, Image: Image57, alt:"Private Villas "},
    { id: 58, Image: Image58, alt:" Villas in Kalyan"},
    { id: 59, Image: Image59, alt:" Villas in Kalyan"},
    { id: 60, Image: Image60, alt:" Villas in Kalyan"},
    { id: 61, Image: Image61, alt:"Private Villas "},
    // { id: 62, Image: Image62, alt:"Private Villas in Kalyan"},
    { id: 63, Image: Image63, alt:" Villas in Kalyan"},
    // { id: 64, Image: Image64, alt:"Private Villas in Kalyan"},
    // { id: 65, Image: Image65, alt:"Private Villas in Kalyan"}
]

export default GalleryData