import React from 'react';
import MetaTags from 'react-meta-tags';

import "../CSS/Gallery.css";
import NavBarComponent from '../Components/NavBarComponent';
import { useState } from 'react';

import GalleryData from '../Components/GalleryData';
import Model from "../Components/Model.jsx";
import Footer from '../Components/Footer';

import ShowImage01 from "../Images/divider.png"
import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
// import { MetaTags } from 'react-meta-tags';

const Imagegallery = () => {

    const [clickImage, setClickImage] = useState(null)
    const [cureentIndex, setCureentIndex] = useState(null)

    const handelClick = (item, index) => {

        setCureentIndex(index);
        setClickImage(item.Image);
        // console.log(clickImage)
        // // console.log(cureentIndex)
    };

    // const some = GalleryData.length
    // console.log(some)

    const handelRotationRight = () => {
        const totallength = GalleryData.length;

        if (cureentIndex + 1 >= totallength) {
            setCureentIndex(0);
            const newurl = GalleryData[0].Image;
            setClickImage(newurl)
            return;
        };
        const newIndex = cureentIndex + 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].Image;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totallength = GalleryData.length;
        if (cureentIndex === 0) {
            setCureentIndex(totallength - 1);
            const newUrl = GalleryData[totallength - 1].Image;
            setClickImage(newUrl);
        }
        const newIndex = cureentIndex - 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].Image;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    return (
        <>

<MetaTags>
      <title>Private Villas in Kalyan | Green Hill Villas</title>
        <meta title="Private Villas in Kalyan | Green Hill Villas" />
        <meta
          name="description"
          content="Indulge in luxury and privacy with our selection of exclusive Private Villas in Kalyan. Escape the hustle and bustle of city life and immerse yourself in tranquillity."
        />
        <meta
          name="keywords"
          content="Best Villas in Kalyan ,Best Luxury Villas in Kalyan with Pool,Villas for Family in Kalyan,4bh Private Villas in Kalyan,Villas with Dining Services in Kalyan,Private Villas in Kalyan,Villas near Kalyan"
        />
        <link rel="canonical" href="https://greenhillvilla.in/private-villas-kalyan" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Private Villas in Kalyan | Green Hill Villas" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://greenhillvilla.in/private-villas-kalyan"/>
        <meta property="og:description" content="Indulge in luxury and privacy with our selection of exclusive Private Villas in Kalyan. Escape the hustle and bustle of city life and immerse yourself in tranquillity." />
        <meta property="og:image" content="https://greenhillvilla.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
            <p className='d-none'>
            Welcome to Green Hill private villas in Kalyan, where art meets luxury amidst the serene landscapes of nature. Nestled in the heart of lush green hills, our villa offers a unique fusion of exquisite artworks and impeccable hospitality, creating an unforgettable experience for our esteemed guests.

At Green Hill private villas in Kalyan, we believe in the power of art to inspire, enrich, and elevate the human spirit. Our gallery page showcases a curated collection of fine art pieces, ranging from paintings and sculptures to photography and mixed media creations. Each piece has been carefully selected to reflect the beauty and diversity of artistic expression, inviting guests to immerse themselves in a world of creativity and imagination.

Stepping into our gallery, visitors are greeted by an eclectic array of artworks that span different genres, styles, and periods. From contemporary masterpieces by emerging artists to timeless classics by renowned masters, there is something to captivate every discerning art lover's palate. Our rotating exhibitions ensure that there is always something new and exciting to discover, making each visit to our gallery a stimulating and enriching experience.

But Green Hill private villas in Kalyan are more than just a showcase for art; it is a sanctuary for the soul, where guests can unwind, rejuvenate, and reconnect with themselves and nature. Surrounded by panoramic views of verdant hills and pristine landscapes, our villa offers a tranquil retreat from the hustle and bustle of everyday life. Whether you're seeking a peaceful getaway, a romantic escape, or a memorable holiday with loved ones, Green Hill Villa provides the perfect setting for relaxation and renewal.

In addition to our gallery, guests can indulge in a host of luxurious amenities and personalized services designed to enhance their stay. From gourmet dining experiences to rejuvenating spa treatments, we go above and beyond to ensure that every moment spent at Green Hill private villas in Kalyan is nothing short of extraordinary.

Come, experience the magic of Green Hill private villas in Kalyan and discover why we are not just a destination, but a destination for the soul.

            </p>

            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            < section className='GallerySection1'>
                <div className='Gallery-banner'>
                    <NavBarComponent />
                    <div className='Gallery-banner-text'>
                        {/* <Slide left>
                        <div className='Text'>
                        <h4>An actor has to burn inside with an outer ease.</h4>
                        </div>
                    </Slide> */}
                        <div className='GS1-Text'>
                            <Zoom top>
                                <h1>Private Villas in Kalyan</h1>
                            </Zoom>
                            <Fade bottom>
                                <p>We dream in colors borrowed from the sea.</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section >




            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className='Image-Gallery'>
            <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <Flip left cascade>
                                <h3>Image gallery</h3>
                                <p> Visual Story Collection</p>
                                {/* <h2>Image gallery</h2> */}
                            </Flip>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Private  in Kalyan" />
                            </div>
                        </div>
                    </div>
                {/* <div className='Image-Gallery-title'>
                    <LightSpeed left cascade>
                        <h4>Image gallery</h4>
                    </LightSpeed>
                    <Zoom>
                        <h3>Image gallery</h3>
                    </Zoom>
                </div> */}
                <div className='container'>
                    <div className='row'>
                        {
                            GalleryData.map((item, index) => {
                                return (
                                    <>
                                        <div className='col-md-4'>
                                            <Zoom>
                                                <div className='G-Images' key={index}>
                                                    <img src={item.Image} alt={item.alt} onClick={() => handelClick(item, index)} />
                                                </div>
                                            </Zoom>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {
                clickImage && (
                    <Model
                        clickImage={clickImage}
                        handelRotationRight={handelRotationRight}
                        setClickImage={setClickImage}
                        handelRotationLeft={handelRotationLeft}
                    />
                )
            }
            <Footer />
        </>
    )
}

export default Imagegallery;