import React from 'react';
import MetaTags from 'react-meta-tags';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "../CSS/Accommodation.css";
import NavBarComponent from '../Components/NavBarComponent';

import { FaWifi, FaBed, FaToilet, FaConciergeBell } from 'react-icons/fa';
import { MdAir, MdOutlineMonitor } from 'react-icons/md';
import { GiComputerFan } from 'react-icons/gi';


import SingleRoom from "../Images/single Room.jpg";
import ShowImage01 from "../Images/divider.png"

import CoupleRoom from "../greenHillVillas-webp/greenHillVilla(39).webp";
import FamilyRoom from "../greenHillVillas-webp/greenHillVilla(22).webp";
import LuxuryRoom from "../Images/luxury room.jpg";
import Footer from "../Components/Footer";

import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';

import CoupleRoom001 from "../greenHillVillas-webp/greenHillVilla(38).webp";
import CoupleRoom002 from "../greenHillVillas-webp/greenHillVilla(6).webp";

import SingleRoom001 from "../Images/Singel Room 001.jpg";
import SingleRoom002 from "../Images/Single Room 002.jpg";

import FamilyRoom001 from "../greenHillVillas-webp/greenHillVilla(19).webp";
import FamilyRoom002 from "../greenHillVillas-webp/greenHillVilla(23).webp";

import LuxaryRoom001 from "../Images/Luxary Room 001.jpg";
import LuxaryRoom002 from "../Images/Luxary Room 002.jpg";

// import { MetaTags } from 'react-meta-tags';
import Threebhk from './Threebhk.jsx';
// import SliderThreeBHK from './SliderThreeBHK.jsx';
import FourBH1 from "../4BHvilla-webp/4bh(2).webp";
import FourBH2 from "../4BHvilla-webp/4bh(3).webp";
import FourBH3 from "../4BHvilla-webp/4bh(4).webp";
import FourBH4 from "../4BHvilla-webp/4bh(5).webp";
import FourBH5 from "../4BHvilla-webp/4bh(6).webp";
import FourBH6 from "../4BHvilla-webp/4bh(7).webp";
import FourBH7 from "../4BHvilla-webp/4bh(8).webp";
import FourBH8 from "../4BHvilla-webp/4bh(9).webp";
import FourBH9 from "../4BHvilla-webp/4bh(10).webp";
import FourBH10 from "../4BHvilla-webp/4bh(11).webp";
import FourBH11 from "../4BHvilla-webp/4bh(12).webp";

import ThreeBh1 from "../3bhvilla-webp/3BhVilla(1).webp";
import ThreeBh2 from "../3bhvilla-webp/3BhVilla(2).webp";
import ThreeBh3 from "../3bhvilla-webp/3BhVilla(5).webp";
import ThreeBh4 from "../3bhvilla-webp/3BhVilla(7).webp";
import ThreeBh5 from "../3bhvilla-webp/3BhVilla(9).webp";
import ThreeBh6 from "../3bhvilla-webp/3BhVilla(10).webp";
import ThreeBh7 from "../3bhvilla-webp/3BhVilla(11).webp";
import ThreeBh8 from "../3bhvilla-webp/3BhVilla(12).webp";
import ThreeBh9 from "../3bhvilla-webp/3BhVilla(13).webp";
import ThreeBh10 from "../3bhvilla-webp/3BhVilla(14).webp";
import ThreeBh11 from "../3bhvilla-webp/3BhVilla(15).webp";
import ThreeBh12 from "../3bhvilla-webp/3BhVilla(17).webp";
import ThreeBh13 from "../3bhvilla-webp/3BhVilla(18).webp";
import ThreeBh14 from "../3bhvilla-webp/3BhVilla(19).webp";
import ThreeBh15 from "../3bhvilla-webp/3BhVilla(20).webp";
import ThreeBh16 from "../3bhvilla-webp/3BhVilla(21).webp";
import ThreeBh17 from "../3bhvilla-webp/3BhVilla(22).webp";

const AccommodationPage = () => {
    return (
        <>
          <MetaTags>
      <title>4bh Private Villas in Kalyan | Green Hill Villas</title>
        <meta title="4bh Private Villas in Kalyan | Green Hill Villas" />
        <meta
          name="description"
          content="Discover the epitome of luxury living with our 4bh Private Villas in Kalyan. Experience unmatched comfort, privacy, and sophistication. "
        />
        <meta
          name="keywords"
          content="Best Villas in Kalyan ,Best Luxury Villas in Kalyan with Pool,Villas for Family in Kalyan,4bh Private Villas in Kalyan,Villas with Dining Services in Kalyan,Private Villas in Kalyan,Villas near Kalyan"
        />
        <link rel="canonical" href="https://greenhillvilla.in/4bh-private-villas-kalyan" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="4bh Private Villas in Kalyan | Green Hill Villas" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://greenhillvilla.in/4bh-private-villas-kalyan"/>
        <meta property="og:description" content="Discover the epitome of luxury living with our 4bh Private Villas in Kalyan. Experience unmatched comfort, privacy, and sophistication. " />
        <meta property="og:image" content="https://greenhillvilla.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>

            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='AccommodationSection1'>
                <div className='Accommodation-banner'>
                    <NavBarComponent />
                    <div className='Accommodation-banner-text'>
                        {/* <Slide left>
                        <div className='Text'>
                            <h4>An actor has to burn inside with an outer ease.</h4>
                            </div>
                        </Slide> */}
                        <div className='AS1-Text'>
                            <Zoom top>
                                <h1>4BH Private Pool Villa</h1>
                            </Zoom>
                            <Fade bottom>
                                <p>Explore our collection of lavish accommodations and start planning your stay with us.</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <LightSpeed left cascade>
                                <h3>Villa's</h3>
                                <p> Escape to Tranquil Luxury</p>
                                {/* <h2>About Us</h2> */}
                            </LightSpeed>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Decoration Image" />
                            </div>
                        </div>
                    </div>
 



            {/* //////////////////////////////////////  section 3 //////////////////////////////////// */}

            <section className='AccommSection3'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='Accomo-right-con-1'>
                            <div className='Accomo-left-rooms-Image1'>
                                <img src={CoupleRoom002} alt="Couple Room" />
                                <Slide left>
                                    <div className='Accomo-left-Couple-room-facility'>
                                        <h4>Facility</h4>
                                        <span><FaWifi /><FaBed /><MdAir /><FaToilet /><FaConciergeBell /><GiComputerFan /><MdOutlineMonitor /></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className='Accomo-right-con-2'>
                            <div className='Accomo-left-rooms-Image2'>
                               {/* <Threebhk /> */}
                                {/* <SliderThreeBHK /> */}
                                
                                <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        // navigation={true}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide> 
                          <div className='row'>
                           
                          <img src={CoupleRoom001} alt="Couple Room Bed" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH1} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH2} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH3} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH4} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH5} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH6} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH7} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH8} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH9} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH10} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={FourBH11} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                      
                       
                    </Swiper>
                                <Slide left>
                                
                                    <div className='Accomo-left-Couple-room-facility2 d-none'>
                                    <h4 className='villa-title'>4bh Villa with private Pool</h4>
                                        <hr></hr>
                                        <p>To enhance your perfect getaway, book one of our couple rooms with many facilities that make your trip memorable. We have 12 couple rooms for 2 people in our resort. </p>
                                        <span></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='Accomo-rooms-1'>
                            <div className='Accomo-rooms-Image1'>
                                <img src={CoupleRoom} alt="couple room interior" />
                                <Slide top className="">
                                    <div className='Accomo-rooms-overlay-con '>
                                        <h4 className='villa-title'>4BH Private pool villa</h4>
                                        <h6>20,000/- for 12 Person Above 12 Person 800/- Extra </h6>
                                        <hr></hr>
                                        <div className='Room_price'>
                                        <label className='villa-para'>
                                        Experience unparalleled luxury at Green Hill Villa's exclusive 4BH private villas in Kalyan with a private pool. Nestled amidst the verdant hills, this haven offers the epitome of privacy and tranquility. Indulge in spacious accommodations, meticulously designed to cater to your every need.
                                         </label>

                                            {/* <h6>From</h6>
                                            <p>$ 1160.00 /night</p> */}
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <hr className='divider' />

            {/* //////////////////////////////////////  section 4 //////////////////////////////////// */}


            <section className='AccommSection4'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='Accomo-rooms-1'>
                            <div className='Accomo-rooms-Image1'>
                                <img src={FamilyRoom} alt="Family Room" />
                                <Slide bottom>
                                    <div className='Accomo-Family-rooms-overlay-con '>
                                        <h4 className='villa-title'>4BH & 3BH Villa with Common pool</h4>
                                        <h6>15,000/- for 12 Person Above 12 Person 800/- Extra charges</h6>
                                        <h6>12,000/- for 9 Person Above 9 Person 800/- Extra charges</h6>
                                        <hr></hr>
                                        <div className='Room_price'>
                                        <label className='villa-para'>
                                        For those seeking community and camaraderie, Green Hill 4BH private villas in Kalyan also offer inviting 3 BHK and 4bh villas with access to a shared pool. Enjoy the vibrant atmosphere of communal living while still relishing the comforts of home.                                        </label>

                                            {/* <h6>From</h6>
                                            <p>$ 1160.00 /night</p> */}
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='Accomo-right-con-1'>
                            <div className='Accomo-left-rooms-Image1'>
                                <img src={FamilyRoom001} alt="Family Room Interior" />
                                <Slide right>
                                    <div className='Accomo-left-Family-room-facility'>
                                        <h4>Facility</h4>
                                        <span><FaWifi /><FaBed /><MdAir /><FaToilet /><FaConciergeBell /><GiComputerFan /><MdOutlineMonitor /></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className='Accomo-right-con-2 '>
                            <div className='Accomo-left-rooms-Image2'>
                                {/* <img src={FamilyRoom002} alt="Master Bed In Family Room" /> */}
                                <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        // navigation={true}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide> 
                          <div className='row'>
                           
                          <img src={ThreeBh1} alt="Couple Room Bed" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh2} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh3} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh4} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh5} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh6} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh7} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh8} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh9} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh10} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh11} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                        <SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh12} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide><SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh13} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide><SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh14} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide><SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh15} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide><SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh16} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide><SwiperSlide>   
                        <div className='row'>
                        <img src={ThreeBh17} alt="Couple Room Bed" />

                        </div>
                        </SwiperSlide>
                      
                       
                    </Swiper>
                                <Slide left>
                                    <div className='d-none Accomo-left-Family-room-facility2'>
                                    <h4 className='villa-title'>4bh Private Villa</h4>
                                        <hr></hr>
                                        <p>Sit back, relax, and renew your soul. Our family-friendly designed rooms are playful with one-of-a-kind vintage pieces, customized furniture and many more facilities. We have 4 family rooms for 4 people.</p>
                                        <span></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />


        </>
    )
}

export default AccommodationPage